<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card class="lfont">
                <v-card-title>
                    <span class="text-h5"><span class="lfont">ຂໍ້ມູນການສຳພາດ</span></span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" v-if="apply_item">
                                <p>ຊື່ຜູ້ສະໝັກ <span class="text-primary">
                                        {{ apply_item.gender == 'male' ? 'ທ້າວ' : 'ນາງ' }} {{ apply_item.name }} {{
                                            apply_item.surname }}</span>
                                </p>

                                <p>ເບີໂທ <span class="text-primary">{{ apply_item.tel }}</span></p>

                                <p>ວັນ,ເວລາສຳພາດ
                                    <v-btn icon color="pink" @click="dialog_add_date_time = true">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </p>
                                <ul v-for="(item, index) in appointment_date" class="text-primary">
                                    <li>{{ index + 1 }}. {{ item.interview_date }} <v-btn icon color="pink"
                                            @click="remove_date_time(index)"><v-icon>mdi-trash-can</v-icon></v-btn></li>
                                </ul><br>


                                <label>ລາຍລະອຽດເພີ່ມເຕີມ</label>
                                <v-textarea outlined rows="3" v-model="note"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_appointment">
                        confirm
                    </v-btn>
                    <v-btn color="red darken-1" text @click="$emit('close')">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>>
        <v-dialog v-model="dialog_add_date_time" max-width="500px" class="lfont">
            <v-card>
                <v-card-title>
                    <span class="lfont">ເລືອກວັນທີ່,ເວລາ</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <label class="lfont">ວັນທີ່</label>
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="date" label="" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                v-on="on" class="lfont"></v-text-field>
                        </template>
                        <v-date-picker v-model="date" type="date" no-title scrollable :rules="dataRules" persistent-hint
                            requried>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>

                    <label class="lfont">ເວລາ</label>
                    <date-picker class="time--picker" style="width: 100%" type="time" v-model="time"></date-picker>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="add_appointment_date_time">
                        add
                    </v-btn>
                    <v-btn color="red" text @click="dialog_add_date_time = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
    components: {
        DatePicker,
    },
    props: {
        dialog: "",
        apply_item: null,
    },
    data: () => ({
        menu: false,
        dataRules: [
            v => !!v || 'ກະລຸນາຂໍ້ມູນ!',
        ],
        dialog_add_date_time: false,
        date: "",
        time: "",
        appointment_date: [],
        note: "",
        server_errors: {
            apply_job_id: "",
            interview_dates: "",
            note: ""
        }
    }),
    methods: {
        remove_date_time(index) {
            this.appointment_date.splice(index, 1);
        },
        add_appointment() {
            let items = {
                "apply_job_id": this.apply_item.id,
                "interview_dates": this.appointment_date,
                "note": this.note,
            };
            this.$emit('save', items);

        },
        add_appointment_date_time() {
            if (this.date == '' || this.time == '') return;
            let time = moment(this.time).format("HH:mm:ss");
            let date = this.date + ' ' + time;
            this.appointment_date.push({
                "interview_date": date
            });
            this.dialog_add_date_time = false;
        }
    },
}
</script>