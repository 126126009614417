<template>
    <div class="lfont">
        <v-row>
            <v-col cols="4" md="4" lg="4" v-if="request">
                <h3>ຂໍ້ມູນການຂໍພະນັກງານ</h3>
                <p>ຕຳແໜ່ງ: <span class="text-primary">{{ request.position.name }}</span></p>
                <p>ພະແນກ: <span class="text-primary">{{ request.working_at_department.name }}</span></p>
                <p>ຜູ້ຂໍ: <span class="text-primary">{{ request.requester.name }} {{ request.requester.surname }}</span></p>
                <p>ຕ້ອງການ <span class="text-primary">{{ request.need_qty }}</span> ຕຳແໜ່ງ</p>
                <p>ວັນທີ່ຂໍ: <span class="text-primary">{{ request.created }}</span></p>
                <p>ວັນທີ່ຕ້ອງການ: <span class="text-primary">{{ request.need_date }}</span></p>
                <p>ສະຖານທີ່ເຮັດວຽກ: <span class="text-primary">{{ request.working_place }}</span></p>
                <p>ລາຍລະອຽດຕຳແໜ່ງ:</p>
                <ul>
                    <li>ຕຳແໜ່ງນີ້ແມ່ນ <span class="text-primary">{{ request.reason.name }}</span></li>
                    <li>ສັນຊາດ <span class="text-primary">{{ request.reason.name }}</span></li>
                    <li>ຮູບແບບສັນຍາ <span class="text-primary">{{ request.reason.name }}</span></li>
                    <li>ໜ້າທີ່ຮັບພິຊອບ <span class="text-primary">{{ request.reason.name }}</span></li>
                </ul>
                <p>ໜ້າທີ່ຮັບພິຊອບ: <span class="text-primary">{{ request.responsibilities }}</span></p>
                <p>ຄຸນນະສົມບັດ:</p>
                <ul>
                    <li v-for="pro_item in request.properties" class="text-primary">{{ pro_item.name }}</li>
                </ul>
            </v-col>
            <v-col cols="8" md="8" lg="8">
                <h3>ຂໍ້ມູນການສະໝັກ</h3>
                <table>
                    <tr>
                        <th width="50">#</th>
                        <th>ຊື່ຜູ້ສະໝັກ</th>
                        <th>ທີ່ຢູ່</th>
                        <th>ເບີໂທ</th>
                        <th>ນັດສຳພາດ</th>
                        <th></th>
                    </tr>
                    <tbody>
                        <tr v-for="(item, index) in selection" class="text-primary">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>

                                {{ item.applicant_apply_job.gender == 'male' ? 'ທ້າວ' : 'ນາງ' }} {{
                                    item.applicant_apply_job.name }} {{ item.applicant_apply_job.surname }}
                            </td>
                            <td>ບ້ານ {{ item.applicant_apply_job.village }}, ເມືອງ {{ item.applicant_apply_job.district.name
                            }}, ແຂວງ {{ item.applicant_apply_job.province.name }}</td>
                            <td>{{ item.applicant_apply_job.tel }}</td>
                            <td>
                                <v-tooltip v-model="showCopy[item.applicant_apply_job.id]" top
                                    v-if="item.interview_appointment">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="green" @click="onCopy(item.applicant_apply_job.id)">
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Copied!</span>
                                </v-tooltip>

                                <v-btn icon color="indigo" @click="add_interview_appointment(item.applicant_apply_job)"
                                    v-else>
                                    <v-icon>mdi-progress-alert</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn icon color="primary" @click="show_detail(item.applicant_apply_job)">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </v-col>
        </v-row>
        <Loading v-if="isLoading" />
        <modalShowApplicantDetail :dialog="dialogShowDetail" :data="applicant_detail" @close="dialogShowDetail = false" />
        <modalAddInterviewAppointment :dialog="dialogAddAppointment" :apply_item="apply_item"
            @close="dialogAddAppointment = false" @save="(items) => add_appointment(items)" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
import modalShowApplicantDetail from '@/components/SelectionApplicant/modals/modalShowApplicantDetail';
import modalAddInterviewAppointment from '@/components/SelectionApplicant/modals/modalAddInterviewAppointment'
export default {
    components: {
        Loading,
        modalShowApplicantDetail,
        modalAddInterviewAppointment,
    },
    data() {
        return {
            showCopy: [],
            dialogAddAppointment: false,
            dialogShowDetail: false,
            isLoading: false,
            request_id: this.$route.params.id,
            selection: null,
            request: null,
            selecting_applicant: [],
            applicant_detail: {},
            apply_item: ""
        }
    },
    methods: {
        onCopy(apply_id) {
            let link = process.env.VUE_APP_URL + 'interview/job/' + apply_id;
            const el = document.createElement("textarea");
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            this.$set(this.showCopy, apply_id, !this.showCopy[apply_id]);
            setTimeout(() => {
                this.$set(this.showCopy, apply_id, !this.showCopy[apply_id]);
            }, 1000);
        },
        add_appointment(items) {
            this.isLoading = true;
            this.dialogAddAppointment = false;
            this.$axios
                .post(`employee/hr/interview/appointment/${this.request_id}`, items)
                .then((res) => {
                    this.isLoading = false;
                    if (res.status === 200) {
                        setTimeout(() => {
                            this.$notification.OpenNotification_AddItem_OnSuccess(
                                "top-right",
                                "primary",
                                3000
                            );
                        }, 300);
                        this.fetch_data();
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },
        add_interview_appointment(apply_item) {
            this.dialogAddAppointment = true;
            this.apply_item = apply_item;
        },
        show_detail(item) {
            this.dialogShowDetail = true;
            this.applicant_detail = item;
            console.log(item)
        },
        fetch_data() {
            this.isLoading = true;
            this.$axios
                .get(`/employee/hr/requester/selected/applicant/${this.request_id}`)
                .then((res) => {
                    if (res.status === 200) {
                        this.selection = res.data.data.selection;
                        this.request = res.data.data.request;
                        console.log(res.data.data)
                        //this.selecting_applicant = res.data.data.request.selection_applicant.map(item => item.apply_job_id);
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        selection_applicant_apply() {
            this.isLoading = true;
            this.$axios
                .post(`/employee/hr/choose/applicant/${this.request_id}`, { "applicant_apply_job_id": this.selecting_applicant })
                .then((res) => {
                    if (res.status === 200) {
                        this.fetch_data()
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        }
    },
    created() {
        this.fetch_data();
    },

}
</script>

<style lang="scss" scoped></style>